import "./App.css";
import Editor from "./editor/Editor";
import React, { useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import Modal from "./modal/Modal"; // Import the Modal component
import {
  formatCSS,
  formatHTML,
  formatJavaScript,
  getDefaultCode,
} from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons"; // Import the icons
import myImage from "../assets/jsserlogo.png";
import { sendPrompt } from "../api/apiService";
import ZipExtractor from "./zipFile/ZipExtractor";
import ZipDownloader from "./zipFile/ZipDownloader";

function App() {
  const [html, setHtml] = useLocalStorage("html", "");
  const [css, setCss] = useLocalStorage("css", "");
  const [js, setJS] = useLocalStorage("javascript", "");
  // const [mes, setMes] = useLocalStorage("mes", []);

  const [srcDoc, setSrcDOC] = useState("");
  const [fullscreen, setFullscreen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [error, setError] = useState("");
  const [editorKey, setEditorKey] = useState(0);
  const [modalPrompt, setModalPrompt] = useState("");
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!html & !css & !js) {
      setHtml(getDefaultCode("html"));
      setCss(getDefaultCode("css"));
      setJS(getDefaultCode("js"));
      setEditorKey((prevKey) => prevKey + 1);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const wrappedJs = `
        try {
          ${js}
        } catch (error) {
          window.parent.postMessage({ type: 'jsError', message: error.message }, '*');
        }
      `;

      setSrcDOC(
        `<!DOCTYPE html>
        <html>
          <body>${html}</body>
          <style>${css}</style>
          <script>${wrappedJs}</script>
        </html>`
      );
    }, 250);

    return () => clearTimeout(timeout);
  }, [html, css, js]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "jsError") {
        setError(event.data.message);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleFullscreenToggle = () => {
    setFullscreen((prev) => !prev);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setModalPrompt(e.target.value);
  };

  const handleSend = async (setInputValue) => {
    try {
      setLoading(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", content: modalPrompt },
      ]);

      const responseData = await sendPrompt({
        html: html || "<html code here>",
        css: css || "<css code here>",
        js: js || "<js code here>",
        error: error || "",
        messages: messages,
        prompt: modalPrompt,
      });

      console.log("RESPONSE------------->", responseData);

      const newHtml = formatHTML(responseData.html);
      const newCss = formatCSS(responseData.css);
      const newJs = formatJavaScript(responseData.js);
      const gptResponse = responseData.response;

      setHtml(newHtml);
      setCss(newCss);
      setJS(newJs);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: `{html: "<html code>", css: "<css code>", js: "<js code>", response: ${gptResponse}}`,
        },
      ]);

      setEditorKey((prevKey) => prevKey + 1);
      setModalPrompt("");
    } catch (error) {
      // console.log("inside erroe handling------->");

      console.error("Error communicating with OpenAI API:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "error",
          content: "some unecpected error occured please try again",
        },
      ]);
      setInputValue(modalPrompt);
      // console.log("the messages-------", messages);
    } finally {
      setLoading(false);
    }
  };

  const handleZipExtracted = (_html, _css, _js) => {
    console.log("inside handle zip extractor------>");

    setHtml(_html);
    setCss(_css);
    setJS(_js);
    setEditorKey((prevKey) => prevKey + 1);

    // Optional: Handle the extracted files here
    // console.log('Extracted files:', files);
  };

  return (
    <>
      <div
        key={editorKey}
        className={`pane top-pane ${fullscreen ? "hidden" : ""}`}
      >
        <Editor
          displayName="HTML"
          language="xml"
          value={html}
          onChange={setHtml}
        />
        <Editor
          displayName="CSS"
          language="css"
          value={css}
          onChange={setCss}
        />
        <Editor
          displayName="JS"
          language="javascript"
          value={js}
          onChange={setJS}
        />
        <img width="100px" src={myImage} alt="My PNG" />
      </div>
      <div
        className={`pane iframe-container ${fullscreen ? "fullscreen" : ""}`}
      >
        <iframe
          srcDoc={srcDoc}
          title="output"
          sandbox="allow-forms allow-same-origin allow-scripts allow-popups allow-modals allow-pointer-lock allow-top-navigation allow-downloads"
          width="100%"
          height="100%"
        />
        <div className="button-container">
          <button
            className="fullscreen-toggle-btn"
            onClick={handleFullscreenToggle}
          >
            <FontAwesomeIcon icon={fullscreen ? faCompress : faExpand} />
          </button>
          <div className="zip-extractor-btn">
            <ZipExtractor onZipExtracted={handleZipExtracted} />
          </div>
          <div className="zip-extractor-btn">

          <ZipDownloader html={html} css={css} js={js} />
          </div>

        </div>

        {!isModalOpen && (
          <button className="open-modal-btn" onClick={handleOpenModal}>
            Prompt
          </button>
        )}
      </div>
      {isModalOpen && (
        <Modal
          title="Prompt Modal"
          onClose={handleCloseModal}
          onSend={handleSend}
          onInputChange={handleInputChange}
          isLoading={loading}
          messages={messages}
        />
      )}

      {/* {error && (
        <div className="error-message">
          <strong>JavaScript Error:</strong> {error}
        </div>
      )} */}
    </>
  );
}

export default App;
