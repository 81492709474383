import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getRandomLoadingMessage } from "../../utils";
import Loader from "../loader/Loader";

function Modal({ title, onClose, onSend, onInputChange, isLoading, messages }) {
  const modalRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const centerModal = useCallback(() => {
    if (modalRef.current) {
      const { width, height } = modalRef.current.getBoundingClientRect();
      const centerX = window.innerWidth / 2 - width / 2;
      const centerY = window.innerHeight / 2 - height / 2;
      setModalPosition({ top: centerY, left: centerX });
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    centerModal();
    window.addEventListener("resize", centerModal);
    return () => window.removeEventListener("resize", centerModal);
  }, [centerModal]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (!isDragging) return;

    const handleMouseMove = (e) => {
      setModalPosition((prev) => ({
        top: e.clientY - offset.y,
        left: e.clientX - offset.x,
      }));
    };

    const handleMouseUp = () => setIsDragging(false);

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, offset]);

  const handleMouseDown = useCallback((e) => {
    const { left, top } = modalRef.current.getBoundingClientRect();
    setIsDragging(true);
    setOffset({
      x: e.clientX - left,
      y: e.clientY - top,
    });
  }, []);

  const handleSend = useCallback(() => {
    if (onSend) {
      onSend(setInputValue);
      setInputValue(""); // Clear the input field
    }
  }, [onSend]);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      if (onInputChange) onInputChange(e);
    },
    [onInputChange]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !isLoading && inputValue.trim()) {
        e.preventDefault(); // Prevent default Enter key behavior
        handleSend(); // Trigger send
      }
    },
    [handleSend, isLoading, inputValue]
  );

  const modalStyle = {
    top: modalPosition ? `${modalPosition.top}px` : "50%",
    left: modalPosition ? `${modalPosition.left}px` : "50%",
    transform: modalPosition ? "none" : "translate(-50%, -50%)",
  };

  function parseGPTResponse(inputString) {
    // Use a regular expression to find the content after "response:"
    const match = inputString.match(/response:\s*(.+)}/);
    if (match && match[1]) {
      // Return the captured group, removing the trailing backtick if present
      return match[1].replace(/`$/, "").trim();
    } else {
      return "GPT response not found in the input string.";
    }
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal"
        ref={modalRef}
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header" onMouseDown={handleMouseDown}>
          <span className="modal-title">{title}</span>
          <button className="modal-close" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-content">
            <div className="messages-container">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.role === "user"
                      ? "user-message"
                      : message.role === "assistant"
                      ? "assistant-message"
                      : "error-message"
                  }`}
                >
                  {message.role === "assistant"
                    ? parseGPTResponse(message.content)
                    : message.content}
                </div>
              ))}
              <div ref={messagesEndRef} />
              {isLoading ? <Loader /> : <></>}
            </div>

            <div className="input-wrapper">
              <input
                type="text"
                className="modal-input"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Add onKeyDown handler
                placeholder={
                  isLoading
                    ? getRandomLoadingMessage()
                    : "type your prompt here.."
                }
              />
              <button
                className="modal-send-btn"
                onClick={handleSend}
                disabled={isLoading || !inputValue.trim()}
              >
                <FontAwesomeIcon
                  icon={isLoading ? faSpinner : faPaperPlane}
                  spin={isLoading}
                />
              </button>
            </div>
            {/* <div className="loading-message">
              {isLoading ? "Loaddding...":"d"}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Modal);
