/**
 * Format HTML code with proper indentation and line breaks.
 *
 * @param {string} html - The HTML code to format.
 * @returns {string} - The formatted HTML code.
 */
const formatHTML = (html) => {
  console.log("INSIDE FORMAT HTML");

  let formatted = "";
  let pad = 0;

  // Use newline character suitable for different platforms
  const newline = "\n";

  // Add line breaks between tags
  html = html.replace(/>\s*</g, `>${newline}<`);

  // Split by new lines
  html.split(newline).forEach((line) => {
    line = line.trim(); // Trim any excess whitespace

    if (line.match(/^<\/\w/)) {
      // Decrease indent level before closing tag
      pad -= 1;
    }

    const indent = "  ".repeat(pad); // Indentation based on current pad level
    if (line) {
      formatted += indent + line + newline; // Add line with proper indentation
    }

    if (line.match(/^<\w[^>]*[^\/]>$/)) {
      // Increase indent level after opening tag
      pad += 1;
    }
  });

  return formatted.trim(); // Trim the final result to remove any trailing newlines
};

/**
 * Format CSS code with proper indentation.
 *
 * @param {string} css - The CSS code to format.
 * @returns {string} - The formatted CSS code.
 */
const formatCSS = (css) => {
  console.log("INSIDE FORMAT CSS");

  let formatted = "";
  let pad = 0;
  const indent = "  "; // Two spaces for each indentation level

  // Remove extra spaces around braces and semicolons
  css = css
    .replace(/\s*({|})\s*/g, "$1") // Remove spaces around braces
    .replace(/\s*;\s*/g, ";") // Remove spaces around semicolons
    .replace(/\s+/g, " "); // Replace multiple spaces with a single space

  // Split the CSS by braces, semicolons, and selectors
  css.split(/({|}|;|\s*(?=[\.#\[]))/).forEach((token) => {
    token = token.trim(); // Trim unnecessary whitespace

    if (!token) return; // Skip empty tokens

    if (token === "}") {
      // Decrease padding before closing brace
      pad -= 1;
      formatted += "\n" + indent.repeat(pad) + token + "\n";
    } else if (token === "{") {
      // Increase padding after opening brace
      formatted += " " + token + "\n";
      pad += 1;
    } else if (token.endsWith(";")) {
      // Regular CSS property
      formatted += indent.repeat(pad) + token + "\n";
    } else if (/^[\.#\[]/.test(token)) {
      // CSS selector or media query
      formatted += indent.repeat(pad) + token;
    } else {
      // Regular CSS declaration or property
      formatted += token;
    }
  });

  return formatted.trim(); // Trim the final result to remove any trailing newlines
};

/**
 * Format JavaScript code with proper indentation.
 *
 * @param {string} jsCode - The JavaScript code to format.
 * @returns {string} - The formatted JavaScript code.
 */
const formatJavaScript = (jsCode) => {
  let formatted = "";
  let pad = 0;
  const indent = "  "; // Two spaces for each indentation level

  // Remove any existing line breaks and extra spaces
  jsCode = jsCode.replace(/\s*({|}|\(|\)|;)\s*/g, "$1").replace(/\s+/g, " ");

  // Split the code by braces, parentheses, and semicolons
  jsCode.split(/({|}|\(|\)|;)/).forEach((token) => {
    token = token.trim(); // Remove unnecessary spaces

    if (!token) return; // Skip empty tokens

    if (token === "}") {
      // Decrease padding before closing brace
      pad -= 1;
      formatted += "\n" + indent.repeat(pad) + token + "\n";
    } else if (token === "{") {
      // Increase padding after opening brace
      formatted += " " + token + "\n";
      pad += 1;
    } else if (token === ";") {
      // Add semicolon at the end of the line
      formatted += token + "\n" + indent.repeat(pad);
    } else if (token === "(" || token === ")") {
      // Keep parentheses in the same line
      formatted += token;
    } else {
      // Regular JavaScript expression or statement
      formatted += indent.repeat(pad) + token;
    }
  });

  return formatted.trim();
};

const getRandomLoadingMessage = () => {
  const messages = [
    "AI is processing your request... 🤖",
    "Letting the AI compute your code... 🧠",
    "AI is working its magic... ✨",
    "AI is crafting your code... 🛠️",
    "AI is analyzing your prompt... 📊",
    "Decoding with AI... 🔍",
    "AI is designing your solution... 🧩",
    "AI is generating your code snippet... 📥",
    "AI is brainstorming your solution... 💭",
    "AI is constructing your code... 🏗️",
    "AI is on the job... 🚀",
    "AI is assembling your code... 🔄",
    "AI is optimizing your prompt... ⚙️",
    "AI is fine-tuning your request... 🎛️",
    "AI is synthesizing your code... 🧬",
    "AI is decoding your needs... 📜",
    "AI is preparing your code... 🎨",
    "AI is composing your solution... 📝",
    "AI is orchestrating your code... 🎻",
    "AI is crafting the perfect response... 🤖✨",
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
};

const getDefaultCode = (language) => {
  switch (language) {
    case "html":
      return `<!DOCTYPE html>
<html lang='en' >
  <head>
    <meta charset='UTF-8'>
      <title>CodePen - Untitled</title>
    </head>
    <body>
      <header style='display: flex; justify-content: start;'>
        <div id='logo'>
          <img src='https://raw.githubusercontent.com/sajithamma/jsser/dev/public/favicon.ico?token=GHSAT0AAAAAACS444FXYFUD75SGMKRTWROUZXFPCYA' alt='Jsser Logo' style='width: 100px;'/>
        </div>
      </header>
      <section id='hero' style='text-align: center;'>
        <h1>Transform Your Prompts into Code with Jsser – Your AI Coding Assistant</h1>
        <p>Jsser leverages AI to generate HTML, CSS, and JavaScript code based on your prompts. Simplify your coding process and focus on creativity.</p>
        <button>Try Jsser Now</button>
      </section>
    </body>
  </html>`;
    case "css":
      return `body {
font-family: Arial, sans-serif  ;
background:  #43C6AC  ;
background: -webkit-linear-gradient(to right,  #F8FFAE,  #43C6AC)  ;
background: linear-gradient(to right,  #F8FFAE,  #43C6AC)  ;

}
#logo {
font-size: 2em  ;

}
#hero {
padding: 50px  ;
color:  #006400  ;

}
#hero h1 {
font-size: 2  .5em  ;

}
#hero p {
color:  #000000  ;
font-size: 1  .5em  ;

}
#hero button {
background-color:  #20bf55  ;
color: white  ;
padding: 10px 20px  ;
border: none  ;
border-radius: 5px  ;

}`;
    default:
      return "console.log('Hello, World!');";
  }
};

// Export the functions
module.exports = {
  formatHTML,
  formatCSS,
  formatJavaScript,
  getRandomLoadingMessage,
  getDefaultCode,
};
