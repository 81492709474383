// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    background-color: #2c2929;
    transition: opacity 0.5s ease;
  }
  
  .editor-container.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .editor-title {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: .5rem;
    background: #333333;
  }
  
  .copy-to-clipboard-btn {
    margin-left: .5rem;
    border: none;
    background: none;
    cursor: pointer;
    color: white;
  }
  
  .codemirror-wrapper {
    flex-grow: 1;
    overflow: hidden;
    transition: height 0.5s ease;
  }
  
  /* Custom scrollbar styles */
  .editor-container ::-webkit-scrollbar {
    width: 12px;
  }
  
  .editor-container ::-webkit-scrollbar-track {
    background: #333333
  }
  
  .editor-container ::-webkit-scrollbar-thumb {
    background-color: hsl(0, 1%, 35%);
    border-radius: 6px;
    /* border: 3px solid hsl(220, 6%, 10%); */
  }
  
  .editor-container ::-webkit-scrollbar-thumb:hover {
    background-color: hsl(223, 23%, 40%);
  }`, "",{"version":3,"sources":["webpack://./src/components/editor/Editor.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,yBAAyB;IACzB,6BAA6B;EAC/B;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,4BAA4B;EAC9B;;EAEA,4BAA4B;EAC5B;IACE,WAAW;EACb;;EAEA;IACE;EACF;;EAEA;IACE,iCAAiC;IACjC,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":[".editor-container {\n    flex-grow: 1;\n    flex-basis: 0;\n    display: flex;\n    flex-direction: column;\n    padding: .5rem;\n    background-color: #2c2929;\n    transition: opacity 0.5s ease;\n  }\n  \n  .editor-container.hidden {\n    opacity: 0;\n    visibility: hidden;\n  }\n  \n  .editor-title {\n    display: flex;\n    justify-content: space-between;\n    color: white;\n    padding: .5rem;\n    background: #333333;\n  }\n  \n  .copy-to-clipboard-btn {\n    margin-left: .5rem;\n    border: none;\n    background: none;\n    cursor: pointer;\n    color: white;\n  }\n  \n  .codemirror-wrapper {\n    flex-grow: 1;\n    overflow: hidden;\n    transition: height 0.5s ease;\n  }\n  \n  /* Custom scrollbar styles */\n  .editor-container ::-webkit-scrollbar {\n    width: 12px;\n  }\n  \n  .editor-container ::-webkit-scrollbar-track {\n    background: #333333\n  }\n  \n  .editor-container ::-webkit-scrollbar-thumb {\n    background-color: hsl(0, 1%, 35%);\n    border-radius: 6px;\n    /* border: 3px solid hsl(220, 6%, 10%); */\n  }\n  \n  .editor-container ::-webkit-scrollbar-thumb:hover {\n    background-color: hsl(223, 23%, 40%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
