// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-container {
  display: flex;
  align-items: start;
  justify-content: left;
  padding: 10px;
  /* background-color: black;  */
}

.dot {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
      transform: scale(0.8);
      background-color: #f1f1f1;
      box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.7);
  }

  50% {
      transform: scale(1.2);
      background-color: #e0e0e0;
      box-shadow: 0 0 0 10px rgba(181, 28, 28, 0); 
  }

  100% {
      transform: scale(0.8);
      background-color: #f1f1f1;
      box-shadow: 0 0 0 0 rgba(222, 221, 221, 0.7); 
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,0CAA0C;AAC5C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;MACI,qBAAqB;MACrB,yBAAyB;MACzB,4CAA4C;EAChD;;EAEA;MACI,qBAAqB;MACrB,yBAAyB;MACzB,2CAA2C;EAC/C;;EAEA;MACI,qBAAqB;MACrB,yBAAyB;MACzB,4CAA4C;EAChD;AACF","sourcesContent":[".dots-container {\n  display: flex;\n  align-items: start;\n  justify-content: left;\n  padding: 10px;\n  /* background-color: black;  */\n}\n\n.dot {\n  height: 10px;\n  width: 10px;\n  margin-right: 10px;\n  border-radius: 10px;\n  background-color: #f1f1f1;\n  animation: pulse 1.5s infinite ease-in-out;\n}\n\n.dot:last-child {\n  margin-right: 0;\n}\n\n.dot:nth-child(1) {\n  animation-delay: -0.3s;\n}\n\n.dot:nth-child(2) {\n  animation-delay: -0.1s;\n}\n\n.dot:nth-child(3) {\n  animation-delay: 0.1s;\n}\n\n@keyframes pulse {\n  0% {\n      transform: scale(0.8);\n      background-color: #f1f1f1;\n      box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.7);\n  }\n\n  50% {\n      transform: scale(1.2);\n      background-color: #e0e0e0;\n      box-shadow: 0 0 0 10px rgba(181, 28, 28, 0); \n  }\n\n  100% {\n      transform: scale(0.8);\n      background-color: #f1f1f1;\n      box-shadow: 0 0 0 0 rgba(222, 221, 221, 0.7); \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
